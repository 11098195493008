.primary-color{
    color: #169EDA;
}

.secondary-color{
    color: #2F373D;
}

.color3{
    color: #8A868C;
}
.roboto-font{
    font-family: 'Roboto', sans-serif;
}

.inter-font{
    font-family: 'Inter', sans-serif;
}

.platinum-color{
    color: rgb(225, 144, 14)
}

.text {
    background: linear-gradient(105.29deg, #2F373D 7.4%, #169EDA 74.71%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
    font-size: 3.3rem;
    line-height: 4rem;
}